import * as api from "../api";

export function bulkNormalize(instances) {
  let temp = [];
  instances.forEach((element) => {
    temp.push(normalizeInstance(element));
  });
  return temp;
}

export function normalizeInstance(instance) {
  if (instance === undefined || instance.length === 0) {
    return;
  }

  return {
    ...instance,
    properties: instance.properties.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.propertyName]: cur,
      };
    }, {}),
  };
}

export function denormalizeInstance(normalizedInstance) {
  return {
    ...normalizedInstance,
    properties: Object.keys(normalizedInstance.properties).map(
      (propertyName) => {
        return normalizedInstance.properties[propertyName];
      }
    ),
  };
}

export function get(normalizedInstance, propertyName) {
  if (normalizedInstance === undefined || propertyName === undefined) {
    return;
  }

  return (
    normalizedInstance.properties[propertyName] &&
    normalizedInstance.properties[propertyName].propertyValue
  );
}

export function set(normalizedInstance, propertyObject) {
  const properties = normalizedInstance.properties;
  return {
    ...normalizedInstance,
    properties: Object.keys(properties).reduce((acc, cur) => {
      if (propertyObject[cur]) {
        acc[cur].propertyValue = propertyObject[cur];
        return acc;
      } else {
        return acc;
      }
    }, properties),
  };
}

export function saveInstance(normalizedInstance) {
  const denormalized = denormalizeInstance(normalizedInstance);

  /*
  Due to schema change, any unkown property will raise an
  error (e.g., 'createdby' is deprecated and raises an error).
   */
  denormalized.createdby && delete denormalized.createdby;
  denormalized.Created_time && delete denormalized.Created_time;
  denormalized.Updated_time && delete denormalized.Updated_time;

  return api.postData("/modules/db/instance/Instance", denormalized);
}

export async function upsertInstance(
  instanceId,
  propertyObject,
  templateName = null
) {

  if (instanceId !== null) {
    const instanceResponse = await api.getData(
      `/modules/db/instance/GetInstancebyId?id=${instanceId}`
    );
    if (instanceResponse.status === 200) {
      return updateInstanceById(instanceId, propertyObject);
    }
  } else {
    if (templateName !== null) {
      return createInstance(templateName, propertyObject);
    }
  }
}

export async function updateInstanceById(instanceId, propertyObject) {
  const updateFailedError = "Instance update failed - ";

  // load instance for given instanceId
  const instanceResponse = await api.getData(
    `/modules/db/instance/GetInstancebyId?id=${instanceId}`
  );
  if (instanceResponse.status !== 200) {
    return {
      success: false,
      error: updateFailedError + "could not load instance!",
    };
  }

  try {
    const instance = await instanceResponse.json();

    // normalize instance
    const NInstance = normalizeInstance(instance);

    // update (set) property values
    const updatedNInstance = set(NInstance, propertyObject);

    // update "text" field
    updatedNInstance["text"] = "settings";

    // update instance on EVADB
    const saveRes = await saveInstance(denormalizeInstance(updatedNInstance));

    if (saveRes.status === 200) {
      return {
        success: true,
        error: "",
      };
    } else {
      return {
        success: false,
        error: updateFailedError + "could not save updated instance!",
      };
    }
  } catch {
    return {
      success: false,
      error: updateFailedError + "could not parse instance data!",
    };
  }
}

export async function createInstance(templateName, propertyObject) {
  const query_data = {
    or: [],
    and: [
      {
        title: templateName,
      },
    ],
    linked_data: 1,
  };

  const json = await api
    .postData("/modules/db/template/getTemplatesByQuery", query_data)
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      }
    });

  if (json[0]) {
    const template = normalizeInstance(json[0]);
    const instance = {
      instanceId: "", // has to be empty string
      templateId: template["templateId"],
      title: template["title"],
      identifier: template["identifier"],
      text: template["text"],
      properties: template["properties"],
    };

    const createInstance = set(instance, propertyObject);
    const saveRes = await saveInstance(createInstance);

    if (saveRes.status === 200) {
      console.log("Updated EVA.DB instance.");
      return {
        success: true,
        error: "",
      };
    }
  } else {
    return {
      success: false,
      error: "Failed to create instance.",
    };
  }
}
