import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/vendor/bootstrap.rtl.only.min.css";
import "react-circular-progressbar/dist/styles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-table/react-table.css";
import 'react-image-lightbox/style.css';
import "video.js/dist/video-js.css";
import "rc-switch/assets/index.css";
import "react-toastify/dist/ReactToastify.min.css";
import { isMultiColorActive, defaultColor,themeColorStorageKey,isDarkSwitchActive } from "./constants/defaultValues";

import moment from 'moment';
import 'moment/locale/de';

import * as R from 'ramda';

/*
Set global language for moment js explicitly.
 */
if (navigator && navigator.language) {
  moment.locale(navigator.language);
}

if (process.env.NODE_ENV === 'development') {
  window.R = R;
  window.moment = moment;
}

const color =
  (isMultiColorActive||isDarkSwitchActive ) && localStorage.getItem(themeColorStorageKey)
    ? localStorage.getItem(themeColorStorageKey)
    : defaultColor;

localStorage.setItem(themeColorStorageKey, color);

let render = () => {
  import('./assets/css/sass/themes/gogo.' + color + '.scss').then(x => {
     require('./AppRenderer');
  });
};
render();