import {
  SMARTOFFICE_GET_CONTACTS,
  SMARTOFFICE_GET_CONTACTS_SUCCESS,
  SMARTOFFICE_GET_CONTACTS_ERROR,
  SMARTOFFICE_GET_CONVERSATIONS,
  SMARTOFFICE_GET_CONVERSATIONS_SUCCESS,
  SMARTOFFICE_GET_CONVERSATIONS_ERROR,
  TRAIN_SET_SELECTED_MESSAGE,
  TRAIN_SELECT_INTENT,
  FETCH_INTENTS,
  FETCH_INTENTS_SUCCESS,
  FETCH_INTENTS_ERROR,
  TRAIN_CLEAR,
  TRAIN_CLEAR_INTENT_SELECTION,
  REMOVE_RECORDING_PATH 
} from "../actions";


export const getSmartofficeContacts = () => ({
  type: SMARTOFFICE_GET_CONTACTS
});

export const getSmartofficeContactsSuccess = (contacts) => {
  return ({
    type: SMARTOFFICE_GET_CONTACTS_SUCCESS,
    payload: { contacts }
  })
};

export const getSmartofficeContactsError = (error) => ({
  type: SMARTOFFICE_GET_CONTACTS_ERROR,
  payload: error
});

export const getSmartofficeConversations = (filter) => ({
  type: SMARTOFFICE_GET_CONVERSATIONS,
  payload: { filter }
});
export const getSmartofficeConversationsSuccess = (conversation) => ({
  type: SMARTOFFICE_GET_CONVERSATIONS_SUCCESS,
  payload: { conversation }
});

export const getSmartofficeConversationsError = (error) => ({
  type: SMARTOFFICE_GET_CONVERSATIONS_ERROR,
  payload: error
});

export const removeRecordingPath = (wfeId) => ({
  type: REMOVE_RECORDING_PATH,
  payload: { wfeId }
})

export const setSelectedMessage = (workflowId, idx) => ({
  type: TRAIN_SET_SELECTED_MESSAGE,
  payload: { workflowId, idx }
});

export const setSelectedIntent = intentName => ({
  type: TRAIN_SELECT_INTENT,
  payload: { intentName }
});

export const getIntents = () => ({
  type: FETCH_INTENTS
})

export const getIntentsSuccess = intents => ({
  type: FETCH_INTENTS_SUCCESS,
  payload: { intents }
});

export const getIntentsError = error => ({
  type: FETCH_INTENTS_ERROR,
  payload: { error }
});

export const clearTrain = () => ({
  type: TRAIN_CLEAR
});

export const clearTrainIntentSelection = () => ({
  type: TRAIN_CLEAR_INTENT_SELECTION
});


