const evaBaseUrl =
    window.REACT_APP_EVA_BASE_URL || process.env.REACT_APP_EVA_BASE_URL || process.env.STORYBOOK_EVA_BASE_URL
const evaBasePort =
    window.REACT_APP_EVA_BASE_PORT || process.env.REACT_APP_EVA_BASE_PORT || process.env.STORYBOOK_EVA_BASE_PORT

if (!evaBaseUrl) {
  throw Error('No EVA endpoint provided.')
}

if (!evaBasePort) {
  console.warn('No port defined for EVA backend.')
}

const baseUrlApi = evaBasePort
  ? `${evaBaseUrl}:${evaBasePort}/api`
  : `${evaBaseUrl}/api`
const baseUrl = evaBasePort ? `${evaBaseUrl}:${evaBasePort}` : evaBaseUrl

/**
 * Append path to currently used backend.
 * @param path
 * @returns {string}
 */
export function genPath(path) {
  return `baseUrl/${path}`
}

/**
 * Return base backend path without port.
 */
export function getBaseUrl() {
  return evaBaseUrl
}

function obtainAuthToken() {
  const token = localStorage.getItem('access_token')

  return {
    Authorization: `Bearer ${token}`,
  }
}

/**
 * This method is used for session checks (user authentication)
 * and will refresh 'access_token', if the user is not authenticated.
 */
async function renewSessionIfRequired(originalRequest, res) {
  if (res.status === 401) {
    const newTokens = await postData(
      '/users/refresh',
      { refresh_token: localStorage.getItem('refresh_token') },
      true
    ).then(refreshRes => {
      if (refreshRes.status === 200) {
        return refreshRes.json()
      } else {
        console.log('Could not authorize.')
      }
    })

    const newRefresh = await fetch(originalRequest)
  } else {
    return res
  }
}

export function getData(path, opts) {
  const getHeaders = {
    'Content-Type': 'application/json',
  }

  let url = `${baseUrlApi}${path}`
  let noAuth = false
  let headers = Object.assign(getHeaders, {}, obtainAuthToken())

  if (opts) {
    if (opts.base === 'raw') {
      url = `${baseUrl}${path}`
    }

    if (opts.noAuth) {
      noAuth = opts.noAuth
    }

    if (opts.token) {
      headers['Authorization'] = 'Bearer ' + opts.token
    }
  }

  const originalRequest = new Request(url, {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  })

  return fetch(originalRequest).then(
    renewSessionIfRequired.bind(this, originalRequest)
  )
}

/**
 * Send POST request to api.
 * @param path
 * @param data
 * @param opts
 * @returns {Promise<Response>}
 */
export function postData(path, data, opts, additionalPostHeaders = {}) {
  let url = `${baseUrlApi}${path}`
  let noAuth = false

  if (opts) {
    if (opts.base === 'raw') {
      url = `${baseUrl}${path}`
    }

    if (opts.noAuth) {
      noAuth = opts.noAuth
    }
  }

  const postHeaders = {
    'Content-Type': 'application/json',
    ...additionalPostHeaders,
  }

  return fetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: noAuth
      ? postHeaders
      : Object.assign(postHeaders, {}, obtainAuthToken()),
    body: JSON.stringify(data),
  })
}

/**
 * Send POST request to api.
 * @param path
 * @param data
 * @param opts
 * @returns {Promise<Response>}
 */
 export function postReset(path, data, opts) {
  let url = `${baseUrlApi}${path}`;

  if (opts) {
    if (opts.base === 'raw') {
      url = `${baseUrl}${path}`;
    }}

  const postHeaders = {
    'Authorization': 'Bearer ' + opts,
    'Content-Type': 'application/json'
  };

  return fetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: postHeaders,
    body: JSON.stringify(data)
  })
}

export function putData(path, data, opts, noAuth) {
  let url = `${baseUrlApi}${path}`;

  if (opts) {
    if (opts.base === 'raw') {
      url = `${baseUrl}${path}`;
    }
  }
  const postHeaders = {
    'Content-Type': 'application/json'
  };

  return fetch(url, {
    method: 'PUT',
    cors: 'no-cors',
    headers: noAuth? postHeaders : Object.assign(postHeaders, {}, obtainAuthToken()),
    body: JSON.stringify(data)
  })
}

export function patchData(path, data, noAuth) {
  const url = `${baseUrlApi}${path}`;

  const postHeaders = {
    'Content-Type': 'application/json'
  };

  return fetch(url, {
    method: 'PATCH',
    cors: 'no-cors',
    headers: noAuth? postHeaders : Object.assign(postHeaders, {}, obtainAuthToken()),
    body: JSON.stringify(data)
  })
}

export function httpDelete(path, data, opts, noAuth) {
  let url = `${baseUrlApi}${path}`;

  // gibt es einen Grund das das vorher nicht verwendet wurde?
  if (opts) {
    if (opts.base === 'raw') {
      url = `${baseUrl}${path}`;
    }
  }

  const deleteHeaders = {
    'Content-Type': 'application/json',
  }

  return fetch(url, {
    method: 'DELETE',
    cors: 'no-cors',
    headers: noAuth
      ? deleteHeaders
      : Object.assign(deleteHeaders, {}, obtainAuthToken()),
    body: JSON.stringify(data),
  })
}

export function postForm(path, mimeType, formData) {
  const url = `${baseUrlApi}${path}`

  const postFormHeaders = {
    /// 'Content-Type': mimeType,
  }

  if (!(formData instanceof FormData)) {
    return new Promise(() => {
      throw "postForm expects data of type 'FormData'"
    })
  }

  return fetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: Object.assign(postFormHeaders, {}, obtainAuthToken()),
    body: formData,
  })
}

export function postMultipart(path, data, headers) {
  const url = `${baseUrlApi}${path}`

  return fetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: Object.assign(headers, {}, obtainAuthToken()),
    body: data,
  })
}

export function customFetch(path, options = {}) {
  const url = `${baseUrlApi}${path}`;

  return fetch(url, {
    headers: Object.assign({}, {}, obtainAuthToken()),
    ...options
  })
}
