import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_INTENTS,
  getSmartofficeContactsSuccess,
  getSmartofficeConversationsSuccess,
  SMARTOFFICE_GET_CONTACTS,
  SMARTOFFICE_GET_CONVERSATIONS,
  getIntentsSuccess,
  getIntentsError
} from "../actions";
import contactData from "../../data/smartoffice_contacts.json";
import * as api from "../../api";
import moment from "moment";
import * as evadb from "../../helpers/evadb";
import axios from "axios";

moment.locale("de");

function* fetchIntents(action) {
  const MOCK_SERVER = "http://localhost:5021";
  const res = yield axios.get(`${MOCK_SERVER}/domain`)
    .then(res => res)
    .catch(err => getIntentsError(err));

  if (res.status === 200) {

    yield put(getIntentsSuccess(res.data));
  } else {

    yield put(getIntentsError(res?.payload?.error));
  }
}

function* fetchConversations(action) {
  const {filter} = action.payload;
  const urlParams = new URLSearchParams();


  if (filter) {
    const hasFeedback = filter => {
      if (filter.hasFeedback && filter.noFeedback) {
        return null;
      } else if (filter.hasFeedback) {
        return "True";
      } else if (filter.noFeedback) {
        return "False";
      }
    };

    // Translate UI filters to query params
    const queryParams = {
      limit: filter.limit,
      skip: filter.limit * (filter.page - 1),
      search: filter.search !== "" && filter.search,
      channel: filter.channel,
      dateBegin: filter.dateBegin && filter.dateBegin.toISOString(),
      dateEnd: filter.dateEnd && filter.dateEnd.toISOString(),
      hasFeedback: hasFeedback(filter)
    };

    Object.keys(queryParams).forEach(key => {
      if (queryParams[key]) {
        urlParams.append(key, queryParams[key]);
      }
    });
  }

  const queryString = urlParams.toString() === "" ? "" : "?" + urlParams.toString();
  const path = "/modules/smo/conversations" + queryString;

  const conversations = yield api.getData(path)
    .then(res => {
      return res.json();
    });

  yield put(getSmartofficeConversationsSuccess({...conversations}));
}

function* fetchContacts(payload) {
  const contactsResponse = yield call(loadContactsAsync);
  const contact = [];

  const data = {
    "or": [],
    "and": [
      {
        "title": "Contacts"
      }
    ],
    "linked_data": 1
  };

  const results = yield api.postData("/modules/db/instance/getInstancesByQuery", data)
    .then(res => {
      return res.json();
    });

  for (let result of results) {
    const instance = evadb.normalizeInstance(result);
    contact.push({
      contactInstanceId: instance.instanceId,
      contactNumber: evadb.get(instance, "contact_number"),
      contactName: evadb.get(instance, "contact_fullname"),
      contactAge: evadb.get(instance, "contact_age"),
      conversationInstanceIds: evadb.get(instance, "contact_conversations")
    });
  }


  const contacts = contactsResponse.map(val => {
    return val.properties.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.propertyName]: cur.propertyValue
      }
    }, {})
  });
  yield put(getSmartofficeContactsSuccess(contact));
}

const loadContactsAsync = async () => {
  return await new Promise((success, fail) => {
    setTimeout(() => {
      success(contactData);
    }, 2000);
  })
    .then(response => response)
    .catch(error => error);
};

export default function* rootSaga() {
  yield all([
    fork(function* watchFetchContacts() {
      yield takeEvery(SMARTOFFICE_GET_CONTACTS, fetchContacts)
    }),
    fork(function* watchFetchConversations() {
      yield takeEvery(SMARTOFFICE_GET_CONVERSATIONS, fetchConversations);
    }),
    fork(function* watchFetchIntents() {
      yield takeEvery(FETCH_INTENTS, fetchIntents);
    })
  ])
}