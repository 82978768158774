import {
  SMARTOFFICE_GET_CONTACTS,
  SMARTOFFICE_GET_CONTACTS_SUCCESS,
  SMARTOFFICE_GET_CONTACTS_ERROR,
  SMARTOFFICE_GET_CONVERSATIONS,
  SMARTOFFICE_GET_CONVERSATIONS_SUCCESS,
  SMARTOFFICE_GET_CONVERSATIONS_ERROR,
  TRAIN_SET_SELECTED_MESSAGE,
  TRAIN_SELECT_INTENT,
  FETCH_INTENTS_SUCCESS,
  FETCH_INTENTS_ERROR,
  FETCH_INTENTS,
  TRAIN_CLEAR,
  TRAIN_CLEAR_INTENT_SELECTION,
  REMOVE_RECORDING_PATH 
} from "../actions";


/**
 * Iterate back till message with sender value "user" is found.
 */
const findInput = (conversationData, idx) => {
  for (let i = idx; i >= 0; i--) {
    if (conversationData[i].sender === "user") {
      return {
        payload: conversationData[i].payload,
        idx: i
      };
    }
  }
}

/**
 * Iterate forward till message with sender value "bot" is found.
 */
const findAnswer = (conversationData, idx) => {
  for (let i = idx; i < conversationData.length; i++) {
    if (conversationData[i].sender === "bot") {
      return {
        payload: conversationData[i].payload,
        idx: i
      };
    }
  }
}


const getInputAnswerPair = (state, workflowId, idx) => {

  const conversationData = state.conversations?.result[workflowId]?.conversationData;
  const message = state.conversations?.result[workflowId]?.conversationData[idx];

  console.log(message);

  switch (message.sender) {
    case "user":
      const answer = findAnswer(conversationData, idx);
      try {
        return {
          input: message.payload,
          answer: answer.payload,
          inputIdx: idx,
          answerIdx: answer.idx
        }
      } catch (error) {
        return {
          input: null,
          answer: null,
          inputIdx: null,
          answerIdx: null
        }
      }

    case "bot":
      const input = findInput(conversationData, idx);
      try {
        return {
          input: input.payload,
          answer: message.payload,
          inputIdx: input.idx,
          answerIdx: idx
        }
      } catch (error) {
        return {
          input: null,
          answer: null,
          inputIdx: null,
          answerIdx: null
        }
      }

    default:
      return {
        input: null,
        answer: null,
        inputIdx: null,
        answerIdx: null
      }
  }
};


const INIT_STATE = {
  conversations: [],
  contacts: [],
  intents: [],
  loadingIntents: true,
  loadingIntentsError: null,
  selectedInputText: null,
  selectedAnswerText: null,
  selectedInputIdx: null,
  selectedAnswerIdx: null,
  selectedIntent: null,
  searchKeyword: "",
  loadingConversations: true,
  loadingContacts: true,
  loadingConversationsError: false,
  loadingContactsError: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SMARTOFFICE_GET_CONTACTS:
      return {...state};

    case SMARTOFFICE_GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.payload.contacts,
        loadingContacts: false
      };

    case SMARTOFFICE_GET_CONTACTS_ERROR:
      return {
        ...state,
        loadingContactsError: action.payload.error,
        loadingContacts: false
      };

    case SMARTOFFICE_GET_CONVERSATIONS:
      return {
        ...state,
        loadingConversations: true
      };

    case SMARTOFFICE_GET_CONVERSATIONS_SUCCESS:

      return {
        ...state,
        conversations: {
          ...action.payload.conversation,
          result: action.payload.conversation.result
        },
        loadingConversations: false
      };

    case SMARTOFFICE_GET_CONVERSATIONS_ERROR:
      return {
        ...state,
        loadingConversationsError: action.payload.error,
        loadingConversations: false
      };

    case REMOVE_RECORDING_PATH:
      const { wfeId } = action.payload;
      if (!wfeId) return state;

      const updatedConvData = state?.conversations?.result?.[wfeId]?.conversationData?.map(el => {
        return {
          ...el,
          call_recording_path: ""
        }
      });

      if (!updatedConvData) return state;

      return {
        ...state,
        conversations: {
          ...state.conversations,
          result: {
            ...state.conversations.result,
            [wfeId]: {
              ...state.conversations.result[wfeId],
              conversationData: updatedConvData
            }
          }
        }
      }
      

    case TRAIN_SET_SELECTED_MESSAGE:
      console.log(action);
      console.log(action.payload.workflowId);
      console.log(action.payload.idx);
      const inputAnswerPair = getInputAnswerPair(state, action.payload.workflowId, action.payload.idx)
      console.log(inputAnswerPair);
      return {
        ...state,
        selectedInput: inputAnswerPair.input,
        selectedAnswer: inputAnswerPair.answer,
        selectedInputIdx: inputAnswerPair.inputIdx,
        selectedAnswerIdx: inputAnswerPair.answerIdx
      };

    case TRAIN_SELECT_INTENT:
      return {
        ...state,
        selectedIntent: action.payload.intentName
      };

    case TRAIN_CLEAR:
      return {
        ...state,
        selectedInput: null,
        selectedAnswer: null,
        selectedInputIdx: null,
        selectedAnswerIdx: null,
        selectedIntent: null,
      }

    case TRAIN_CLEAR_INTENT_SELECTION:
      return {
        ...state,
        selectedIntent: null,
      };

    case FETCH_INTENTS:
      return {
        ...state,
        loadingIntents: true,
        loadingIntentsError: null
      }

    case FETCH_INTENTS_SUCCESS:

      return {
        ...state,
        intents: action.payload.intents,
        loadingIntents: false,
        loadingIntentsError: null
      }

    case FETCH_INTENTS_ERROR:

      return {
        ...state,
        loadingIntents: false,
        loadingIntentsError: action.payload.error
      }


    default:
      return state;
  }
}
